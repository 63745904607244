import React, {useState, useEffect, useRef} from 'react';
import styles from './MainMenu.module.css';
import {Link} from 'react-router-dom';
import classes from 'classnames';
import Icon from '../Icon';
import OneFJefFooter from '../OneFJefFooter';
import PaintersPallet from '../../assets/PaintersPallet.png'
import {formatNumber} from '../../utils/numberFormat.js';

function MainMenu(props) {
  const {personName, editorScore, groupSummaries} = props;

  const [opened, setOpened] = useState(false);

  const dropdown = useRef(null);
  const grayArea = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", handleClosed);
    dropdown.current.addEventListener("click", handleDisplay);
    grayArea.current.addEventListener("click", handleClosed);
    document.body.addEventListener('keyup', checkForKeypress);
    return () =>
      document.body.removeEventListener("click", handleClosed);
  }, []);

  const checkForKeypress = (evt) => {
    evt.key === 'Escape' && opened && setOpened(false);
  }

  const handleDisplay = (ev, preventAction) => {
    ev.stopPropagation();
    setOpened(!opened);
  }

  const handleClosed = () => {
    setOpened(false);
  }

  return (
    <div className={styles.container}>
      <input type="checkbox" className={styles.check} id="checked" checked={opened || false} onChange={() => {
      }} ref={dropdown}/>
      <label className={styles.menuBtn} onClick={handleDisplay}>
        <span className={classes(styles.bar, styles.top)}/>
        <span className={classes(styles.bar, styles.middle)}/>
        <span className={classes(styles.bar, styles.bottom)}/>
      </label>
      <label className={styles.closeMenu} ref={grayArea} onClick={handleClosed}/>
      <nav className={styles.drawerMenu}>
        <span className={styles.loggedIn}>Logged in:</span>
        <span className={styles.personName}>{personName}</span>
        <span className={styles.score}>{formatNumber(editorScore, true, false, 1)}</span>
        <ul className={styles.unorderedList}>
          <li>
            <Link to={`/myWorks`} className={styles.menuItemDocuments}>
              <Icon pathName={'folder_only'} premium={true} className={styles.icon}/>
              <div className={styles.menuTextPlus}>My Documents</div>
            </Link>
          </li>
          <li>
            <Link to={`/myGroups`} className={styles.menuItemPlus}>
              <Icon pathName={'users0'} premium={true} className={styles.icon}/>
              <div className={styles.menuTextPlus}>My Groups and Contacts</div>
            </Link>
          </li>
          {/* <li>
            <hr/>
          </li>
          <li>
            <Link to={`/giveAccessToWorks`} className={styles.menuItem}>
              <Icon pathName={'share'} premium={true} className={styles.icon} fillColor={'black'}/>
              <div className={styles.menuText}>Give access by document</div>
            </Link>
          </li> */}
          {/*{workSummary && personId === workSummary.authorPersonId && <li><hr /></li>}*/}
          {/*{workSummary && personId === workSummary.authorPersonId &&*/}
          {/*		<li>*/}
          {/*				<Link to={`/workSettings`} className={styles.menuItem}>*/}
          {/*						<Icon pathName={'cog'} premium={true} className={styles.icon}/>*/}
          {/*					<div className={styles.menuText}>This Document's Settings</div>*/}
          {/*				</Link>*/}
          {/*		</li>*/}
          {/*}*/}
          {/* <li>
            <hr/>
          </li> */}
          <li>
            <Link to={'/workAddNew'} className={styles.menuItemPlus}>
              <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={'green'}/>
              <div className={styles.menuText}>Add New Document</div>
            </Link>
          </li>
          <li>
            <Link to={'/editorInviteOptions'} className={styles.menuItemPlus}>
              <Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'green'}/>
              <div className={styles.menuText}>Invite New Editor</div>
            </Link>
          </li>
          <li>
            <hr />
          </li>
          <li>
            <Link to={'/textStyleSettings'} className={styles.menuItemPlus}>
              <img src={PaintersPallet} height={20} style={{marginRight: '5px'}} />
              <div className={styles.menuText}>Text Style Settings</div>
            </Link>
          </li>
          {/*<li>*/}
          {/*		<Link to={'/groupTypeChoice'} className={styles.menuItem}>*/}
          {/*			<Icon pathName={'users0'} premium={true} superscript={'plus'} supFillColor={'green'} className={styles.icon}/>*/}
          {/*			<div className={styles.menuText}>Create New Editor Group</div>*/}
          {/*		</Link>*/}
          {/*</li>*/}
          {/*<li><hr /></li>*/}
          {/*<li>*/}
          {/*		<Link to={`/report/e/edit`} className={styles.menuItem}>*/}
          {/*			<Icon pathName={'graph_report'} premium={true} className={styles.icon}/>*/}
          {/*			<div className={styles.menuText}>Editor Reports</div>*/}
          {/*		</Link>*/}
          {/*</li>*/}
          {/*<li><hr /></li>*/}
          {/*<li>*/}
          {/*		<Link to={`/openCommunity`} className={styles.menuItem}>*/}
          {/*			<Icon pathName={'earth'} premium={true} className={styles.icon}/>*/}
          {/*			<div className={styles.menuText}>Open Community</div>*/}
          {/*		</Link>*/}
          {/*</li>*/}
          <li>
            <hr/>
          </li>
          <li>
            <Link to={`/myProfile`} className={styles.menuItem}>
              <Icon pathName={'portrait'} premium={true} className={styles.icon}/>
              <div className={styles.menuTextPlus}>My Profile</div>
            </Link>
          </li>
          <li>
            <Link to={`/logout`} className={styles.menuItem}>
              <Icon pathName={'stop_circle'} premium={true} className={styles.icon}/>
              <div className={styles.menuTextPlus}>Logout</div>
            </Link>
          </li>
        </ul>
        <ul className={styles.bottomDiv}>
          <OneFJefFooter smallWidth/>
        </ul>
      </nav>
    </div>
  )
}


//<li><Link to={`/bidRequests`} className={styles.menuItem}>Bid Requests</Link></li>
export default MainMenu;