import React from 'react';
import styles from './EditorNameDateSentence.module.css';
import classes from "classnames";
import DateMoment from '../DateMoment/DateMoment'
//import DiffWord from '../DiffWord/DiffWord'
import * as editorService from '../../services/editor-dom'
let jsdiff = require('diff');

function EditorNameDateSentence(props) {
  const { edits = [], edit, index, editorColor, personConfig, originalSentenceText, differenceEditText, handleSetChosenMoveEdit, handleSetChosenChangeStyleEdit, 
    isHistory, personId, tabsData, currentElement, isTranslation, segment, segments} = props;

	const getDisplay = (edit) => {
		if (edit.type === 'DELETEPARAGRAPH') {
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <span key={index}><img src={`/inline/paragraph-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete Paragraph Break</span>
		} else if (edit.type === 'DELETESENTENCE') {
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <span key={index}><img src={`/inline/sentence-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete Sentence<div dangerouslySetInnerHTML={{__html: originalSentenceText}}/></span>
    } else if (edit.type === 'ADDPARAGRAPHSENTENCE') {
      let editorColorWithoutSymbol = editorColor && editorColor.replace('#', '')
      return <span key={index}><img src={`/inline/paragraph-sentence-plus-${editorColorWithoutSymbol}.png`} alt={' '} height={22} /> Add Paragraph and Sentence<div dangerouslySetInnerHTML={{ __html: differenceEditText }} /></span>
    } else if (edit.type === 'ADDSENTENCE') {
      let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
      return <span key={index}><img src={`/inline/sentence-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add Sentence<div dangerouslySetInnerHTML={{__html: differenceEditText}}/></span>
		} else if (edit.type === 'ADDPARAGRAPH') {
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <span key={index}><img src={`/inline/paragraph-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add Paragraph Break</span>
		} else if (edit.type === 'ADDLISTITEM') {
      //We need the entire edits records because the author is not going to have the spans on her view here. We need to reach into the edits records to find this particular edit to get the text.
      //const editAddListItem = edits.filter(m => m.editSegmentId === Number(currentElement.dataset.editSegmentId))[0]
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <span key={index}><img src={`/inline/list-item-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add List Item<div dangerouslySetInnerHTML={{__html: edit && edit.text.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '') + ' '}}/></span>
		} else if (edit.type === 'DELETELISTITEM') {
			const editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			const listItemText = editorService.getListItemText(currentElement, segments)
			return <span key={index}><img src={`/inline/list-item-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete List Item<div dangerouslySetInnerHTML={{__html: listItemText}}/></span>
		} else if (edit.type === 'REORDERLISTITEMS') {
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <span key={index}><img src={`/inline/reorder-list-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Re-order List Items</span>
		} else if (edit.type === 'ADDTAB') {
			return <div key={index}><span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData)}`}}>&nbsp;tab</span><span>Add Tab</span></div>
		} else if (edit.type === 'DELETETAB') {
			return <div key={index}><span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData, 'reverse')}`}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tab</span><span>Delete Tab</span></div>
		} else if (edit.type === 'LISTLEVELMINUS') {
			return <div key={index}><span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData, 'reverse')}`}}>&nbsp;&nbsp;&nbsp;level</span><span>Move list item to the left</span></div>
		} else if (edit.type === 'LISTLEVELPLUS') {
			return <div key={index}><span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData)}`}}>&nbsp;level</span><span>Move list item to the right</span></div>
		} else if (edit.type === 'MOVE') {
			let moveSegmentsArray = edit.moveSegmentsArray.split(',')
			let sentenceCount = moveSegmentsArray.length
			let editorColorWithoutSymbol = editorColor && editorColor.replace('#','')
			return <div key={index}>
								<div className={styles.row}>
									<img src={`/inline/move-start-${editorColorWithoutSymbol}.svg`} alt={'move'} height={15} />
									<div className={styles.space}>{`Move ${sentenceCount} sentence${sentenceCount > 1 ? 's' : ''}`}</div>
								</div>
								<div className={styles.rowSpace}>
									<div onClick={() => {
										handleSetChosenMoveEdit(edit.editSegmentId)
										editorService.showTarget(moveSegmentsArray[moveSegmentsArray.length - 1], edit.moveToElementId, edit.editSegmentId)
									}} className={styles.linkSpace}>See target</div>
									<div onClick={() => {
										handleSetChosenMoveEdit(edit.editSegmentId)
										editorService.showMoveSentences(moveSegmentsArray, edit.editSegmentId)
									}} className={styles.linkSpace}>{`See sentence${sentenceCount > 1 ? 's' : ''}`}</div>
								</div>
						 </div>
    } else if (edit.type === 'CHANGESTYLE') {
      let changeStyleSegmentsArray = edit.changeStyleSegmentsArray.split(',')
      let sentenceCount = changeStyleSegmentsArray.length
      let editorColorWithoutSymbol = editorColor && editorColor.replace('#', '')
      return <div key={index}>
        <div className={styles.row}>
          <img src={`/inline/move-start-${editorColorWithoutSymbol}.svg`} alt={'move'} height={15} />
          <div className={styles.space}>{`Move ${sentenceCount} sentence${sentenceCount > 1 ? 's' : ''}`}</div>
        </div>
        <div className={styles.rowSpace}>
          <div onClick={() => {
            handleSetChosenChangeStyleEdit(edit.editSegmentId)
            editorService.showChangeStyle(changeStyleSegmentsArray, edit.editSegmentId)
          }} className={styles.linkSpace}>{`See sentence${sentenceCount > 1 ? 's' : ''}`}</div>
        </div>
      </div>
		} else {
			return differenceEditText
		}
	}

  const setDiffWord = (inputA, inputB) => {
    const regex = "/<(.|\n)*?>/";
    inputA = inputA && inputA.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&#xa0;/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .trim();

    inputB = inputB && inputB.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&#xa0;/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .trim();

    const diff = jsdiff.diffWords(inputA, inputB); //fnMap[props.type]
    const result = diff && diff.length > 0 && diff.map((part, index) => {
      if (part.added) {
        return <ins key={index}>{part.value}</ins>
      }
      if (part.removed) {
        return <del key={index}>{part.value}</del>
      }
      return <span key={index}>{part.value}</span>
    });
    return result
  }

	return (
    <div className={styles.container}>
			<div>
        {isTranslation && segment.translatedAuthor && edit.type === 'TEXT' &&
          <div className={styles.bottomSpace}>
            <div className={styles.row}>
              <span className={styles.translatedAuthor}>TRANSLATED DECISION BY AUTHOR </span>
              <DateMoment date={segment.translatedAuthorDate} dateTextClass={styles.date}/>
            </div>
            <span dangerouslySetInnerHTML={{__html: getDisplay(edit)}} />
            <hr/>
          </div>
        }
        <span className={styles.authorDateLine}>
            <div className={styles.colorBox} style={{backgroundColor: editorColor}} />
          {`${edit.firstName} ${edit.lastName} `}
          <DateMoment date={edit.entryDate} dateTextClass={styles.date}/>
          {/*{edit.isComment && <span className={styles.isComment}> Comment </span>}*/}
          {/*{!edit.pendingFlag && edit.authorAccepted && <span className={styles.isAccepted}> Accepted </span>}*/}
          {/*{!edit.pendingFlag && !edit.authorAccepted && <span className={styles.isProcessed}> Processed </span>}*/}
        </span>
        <div className={classes(styles.editText, (edit.isComment ? styles.isCommentText : ''))} id={edit.personId}>
          {edit.type === 'TEXT'
            ? !isTranslation && personConfig.editDifferenceView && originalSentenceText && differenceEditText
              ? setDiffWord(originalSentenceText, differenceEditText)
              : <span dangerouslySetInnerHTML={{__html: getDisplay(edit)}} />
            : getDisplay(edit)
          }
          {/*{!isTranslation && personConfig.editDifferenceView && originalSentenceText && differenceEditText &&*/}
          {/*  <div className={styles.diffText}><hr/>*/}
          {/*    {setDiffWord(originalSentenceText, differenceEditText)}*/}
          {/*  </div>*/}
          {/*}*/}
          {edit.comment &&
            <div>
              <div className={styles.textSmallGray}>{edit.firstName}'s comment:</div>
              <div className={styles.editorComment}>{edit.comment}</div>
            </div>
          }
        </div>
			</div>
			{isHistory &&
				<div className={styles.historyDate}>
					<div className={styles.rejectAccept}>{edit.acceptedDate ? 'Accepted: ' : 'Rejected: '}</div>
					<DateMoment date={edit.acceptedDate ? edit.acceptedDate : edit.rejectedDate} dateTextClass={styles.date}/>
				</div>
			}
		</div>
  )
}

export default EditorNameDateSentence