import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom'
import WorkAddView from '../views/WorkAddView';
import {connect} from 'react-redux';
import * as actionWorks from '../actions/works.js';
import * as fromWorks from '../reducers/works.js';
import * as actionTextStyles from '../actions/text-styles.js';
import * as actionWorkFilter from '../actions/works.js';
import * as actionFileTreeSubContents from '../actions/work-file-tree-sub-contents.js'
import * as actionGroups from "../actions/groups";
import * as actionLanguage from "../actions/language-list";
import * as actionContacts from "../actions/contacts";
import * as actionWorkAddSetting from "../actions/work-add-setting";

import {
  selectMe,
  selectLanguages,
  selectWorkTypes,
  selectGroups,
	selectWorkSummary,
  selectContacts,
	selectWorkFileTree,
	selectTextImageUploads,
	selectTextImageGroups,
	selectWorkAddSettings,
	selectTextStyleGroups,
} from '../store.js';

const mapStateToProps = (state, props) => {
	let me = selectMe(state);
	let isAuthorAlready = fromWorks.selectWorks(state.works) ? fromWorks.selectWorks(state.works).filter(m => m.personId === me.personId)[0] : [];
	let isNewUser = !isAuthorAlready;
	let isWorkNameChange = false;
	const workTypes = selectWorkTypes(state)
  const incomingFilePenspringChapter = selectWorkSummary(state)
  const contacts = selectContacts(state)
  let contactOptions = contacts && contacts.length > 0 && contacts.reduce((acc, m) => {
    let option = {
      id: m.personId,
      label: m.firstName + ' ' + m.lastName
    }
    return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
  }, [])

	let workTypeList = workTypes && workTypes.length > 0 && workTypes.reduce((acc, m) => {
		let option = {id: m.workTypeId, label: m.name, code: m.code}
		return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
	}, [])

	let textImageGroups = selectTextImageGroups(state)

	textImageGroups = textImageGroups?.map(m => {
		m.id = m.textImageGroupId
		m.label = m.name.length > 100 ? m.name.substring(0, 100) + '...' : m.name
		return m
	})

	let settings = selectWorkAddSettings(state)
	const workAddSettings = settings?.reduce((acc, m) => {
		let option = { ...m, id: m.workAddSettingId, label: m.name }
		return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
	}, [])

	return {
		personId: me.personId,
		isNewUser,
		workTypeList,
		isWorkNameChange, //What is this?
		workName: null, //What is this?
		languageChosen: 1,
		groupChosen: props && props.params && props.params.groupChosen,
		hasSections: null,
		languageList: selectLanguages(state),
		groups: selectGroups(state),
		incomingFilePenspringChapter,
		contacts,
    contactOptions,
		fileTreeExplorer: selectWorkFileTree(state),
		textImageUploads: selectTextImageUploads(state),
		textImageGroups,
		workAddSettings,
		textStyleGroups: selectTextStyleGroups(state),
	}
};

const bindActionsToDispatch = dispatch => ({
	getWorkTypes: () => dispatch(actionWorks.getWorkTypes()),
	getWorkList: (personId) => dispatch(actionWorkFilter.init(personId)),
  getWorkSummary: (personId, workId) => dispatch(actionWorks.getWorkSummary(personId, workId)),
  getContacts: (personId) => dispatch(actionContacts.getContacts(personId)),

	//WorkListPenspringDrive
	getMyWorks: (personId, includeOriginatingEditor) => dispatch(actionFileTreeSubContents.getMyWorks(personId, includeOriginatingEditor)),
	// assignWorkToWorkFolder: (personId, workId, workFolderId) => dispatch(actionFileTreeSubContents.assignWorkToWorkFolder(personId, workId, workFolderId)),
	// addWorkFolder: (personId, parentWorkFolderId, folderTitle, mineOrOthers) => dispatch(actionFileTreeSubContents.addWorkFolder(personId, parentWorkFolderId, folderTitle, mineOrOthers)),
	// deleteWorkFolder: (personId, workFolderId, deleteFilesAlso) => dispatch(actionFileTreeSubContents.deleteWorkFolder(personId, workFolderId, deleteFilesAlso)),
	// removeMemberByWorkFolderId: (personId, workFolderId) => dispatch(actionGroups.removeMemberByWorkFolderId(personId, workFolderId)),
	// removeEditorByAuthor: (editorPersonId, authorPersonId, workFolderId) => dispatch(actionEditorAssign.removeEditorByAuthor(editorPersonId, authorPersonId, workFolderId)),
	toggleExpanded: (personId, workFolderId) => dispatch(actionFileTreeSubContents.toggleExpanded(personId, workFolderId)),
	//setPenspringDistributeSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringDistributeSubmitted(personId, workId)),
	//deleteWorkFromFolder: (mineOrOthers, personId, levelType, id) => dispatch(actionWorks.deleteWorkFromFolder(mineOrOthers, personId, levelType, id)),
	getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
	getLanguages: () => dispatch(actionLanguage.getLanguages()),

	//TextImageUpload
	getTextImageUploads: (textImageGroupId) => dispatch(actionWorks.getTextImageUploads(textImageGroupId)),
	removeTextImageUpload: (textImageGroupId) => dispatch(actionWorks.removeTextImageUpload(textImageGroupId)),
	setTextImageUpload: (textImageGroupId, data) => dispatch(actionWorks.setTextImageUpload(textImageGroupId, data)),
	getTextImageGroups: () => dispatch(actionWorks.getTextImageGroups()),
	updateSequenceTextImageUpload: (textImageUploadId, sequence) => dispatch(actionWorks.updateSequenceTextImageUpload(textImageUploadId, sequence)),
	updateNameTextImageUpload: (textImageUploadId, name) => dispatch(actionWorks.updateNameTextImageUpload(textImageUploadId, name)),
	saveWorkAddSetting: (settings) => dispatch(actionWorkAddSetting.saveWorkAddSetting(settings)),
	getWorkAddSettings: () => dispatch(actionWorkAddSetting.getWorkAddSettings()),
	deleteWorkAddSetting: (workAddSettingId) => dispatch(actionWorkAddSetting.deleteWorkAddSetting(workAddSettingId)),
	getTextStyleGroups: () => dispatch(actionTextStyles.getTextStyleGroups()),
});

const mergeAllProps = (store, actions) => ({
	...store, ...actions,
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
	mergeAllProps
);

function Container(props) {
	const params = useParams()
	const { 
		personId, 
		getMyWorks, 
		getGroups, 
		getContacts, 
		getWorkSummary, 
		getWorkTypes, 
		getLanguages, 
		getTextImageGroups, 
		getWorkAddSettings, 
		getTextStyleGroups} = props

	useEffect(() => {
		getWorkTypes()
		if (personId) {
			getWorkSummary(personId, params.workId)
			getGroups(personId)
			getContacts(personId)
			getMyWorks(personId, true) //includeOriginatingEditor=true
			getLanguages()
			getTextImageGroups()
			getWorkAddSettings()
			getTextStyleGroups()
		}
	}, [personId])

	return <WorkAddView {...props }/>
}

export default storeConnector(Container);
