import * as types from './actionTypes'
import {apiHost} from '../api_host.js'
import { guidEmpty } from '../utils/GuidValidate.js'

export const getTextStyleGroups = () => {
	return dispatch => {
		return fetch(`${apiHost}ebi/textStyles/get`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_STYLE_GROUPS_INIT, payload: response })
			})
	}
}

export const setTextStyleGroup = (settings, name, editTextStyleGroupId) => {
	const settingsArray = Object.entries(settings).map(([code, value]) => ({ code, value }));
	editTextStyleGroupId = editTextStyleGroupId && editTextStyleGroupId !== guidEmpty ? editTextStyleGroupId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textStyles/set/${encodeURIComponent(name)}/${editTextStyleGroupId}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(settingsArray),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_STYLE_GROUPS_INIT, payload: response })
			})
	}
}

export const deleteTextStyleGroup = (textStyleGroupId) => {
	textStyleGroupId = textStyleGroupId && textStyleGroupId !== guidEmpty ? textStyleGroupId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textStyles/remove/${textStyleGroupId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_STYLE_GROUPS_INIT, payload: response })
			})
	}
}

export const getTextStyleFonts = () => {
	return dispatch => {
		return fetch(`${apiHost}ebi/textStyles/fonts/get`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_STYLE_FONTS_INIT, payload: response })
			})
	}
}

