import React, {useState, useEffect} from 'react';
import styles from './WorkListPenspringDrive.module.css';
import WorkSummaryLine from '../../components/WorkSummaryLine'
import FileTreeSubContents from '../../components/WorkFileTreeSubContents'
import classes from 'classnames'
import Checkbox from '../../components/Checkbox'
import { guidEmpty } from '../../utils/GuidValidate'

function WorkListPenspringDrive(props) {
	const {
		personId,
		setWork,
		work,
		filePenspring,
		fileTreeExplorer = [],
		getMyWorks,
		assignWorkToWorkFolder,
		addWorkFolder,
		deleteWorkFolder,
		removeMemberByWorkFolderId,
		removeEditorByAuthor,
		toggleExpanded,
		setPenspringDistributeSubmitted,
		deleteWorkFromFolder,
		groups,
	} = props;

	const [fileNamesFiltered, setFileNamesFiltered] = useState()
	const [filterDocName, setFilterDocName] = useState('')
	const [fileNames, setFileNames] = useState()
	const [workFolderMineExpanded, setWorkFolderMineExpanded] = useState(props.personConfig && props.personConfig.workFolderMineExpanded)
	const [fileTreeExpanded, setFileTreeExpanded] = useState()
	const [chosenWork, setChosenWork] = useState(work)
	const [chosenFolder, setChosenFolder] = useState('')
	const [showTempControls, setShowFolderControls] = useState(false)
	const [isMoveWorkId, setIsMoveWorkId] = useState()
	const [showDocumentDetails, setShowDocumentDetails] = useState(true)

	useEffect(() => {
		if (work && work.workName && !(chosenWork && (chosenWork.title || chosenWork.workName))) {
			handleSetChosenWork(work)
		}
	}, [work])

	useEffect(() => {
		let showDetails = localStorage.getItem('showDocumentDetails')
		if (showDetails == 'true') setShowDocumentDetails(true)
		let newExpanded = localStorage.getItem("fileTreeExpanded")
		if (newExpanded) newExpanded = newExpanded.split(",")
		setFileTreeExpanded(newExpanded)
	}, [])

	useEffect(() => {
		let hasInitFileTreeExpanded = localStorage.getItem("hasInitFileTreeExpanded")
		if (!hasInitFileTreeExpanded) {
			setTimeout(() => handleToggleAllExpanded(true), 1000)
		}
	}, [fileTreeExplorer])

	useEffect(() => {
		let filtered = fileNames && fileNames.length > 0 ? [...fileNames] : []
		if (filterDocName) filtered = filtered && filtered.length > 0 && filtered.filter(m => m.name.toLowerCase().indexOf(filterDocName.toLowerCase()) > -1)
		setFileNamesFiltered(filtered)
	}, [fileNames, filterDocName])

	const handleToggleAllExpanded = (expandAll) => {
		const { personId, updatePersonConfig } = props
		toggleAllExpandedLocal(expandAll)
		// updatePersonConfig(personId, 'WorkFolderMineExpanded', expandAll)
		// updatePersonConfig(personId, 'WorkFolderOthersExpanded', expandAll)
		setWorkFolderMineExpanded(expandAll)
	}

	const handleSetShowDocumentDetails = () => {
		setShowDocumentDetails(!showDocumentDetails)
		localStorage.setItem('showDocumentDetails', !showDocumentDetails)
	}

	const getWorkFolderIds = (folders, workFolderIds = []) => {
		folders && folders.length > 0 && folders.forEach(folder => {
			workFolderIds.push(folder.workFolderId);
			if (folder.subFolders && folder.subFolders.length > 0) {
				getWorkFolderIds(folder.subFolders, workFolderIds);
			}
		});

		return workFolderIds;
	}

	const toggleAllExpandedLocal = (expandAll) => {
		let newExpanded = ''
		if (expandAll) {
			let newExpandedArray = getWorkFolderIds(fileTreeExplorer)
			if (newExpandedArray && newExpandedArray.length > 0) {
				newExpanded = newExpandedArray.join(",")
			}
		} else {

		}
		localStorage.setItem("fileTreeExpanded", newExpanded);
		localStorage.setItem("hasInitFileTreeExpanded", true);
		setFileTreeExpanded(newExpanded.split(","))
	}

	const moveWorkToFolder = (type, workFolderId) => {
		if (type === 'end') {
			props.assignWorkToWorkFolder(personId, isMoveWorkId, workFolderId)
		}
		setIsMoveWorkId(type === 'start' ? chosenWork.workId : null)
	}

	const handleSetChosenWork = (newWork) => {
		let updateWork
		setChosenWork(newWork)
		setChosenFolder('')
		setWork(newWork)
	}

	const handleSetChosenFolder = (value) => {
		setChosenFolder(value)
		setChosenWork('')
	}

	const toggleExpandedLocal = (workFolderId, workFolderName, forceExpanded) => {
		let newExpanded = localStorage.getItem("fileTreeExpanded")
		if (newExpanded) newExpanded = JSON.parse(newExpanded)
		if (!forceExpanded && newExpanded && newExpanded.length > 0 && newExpanded.filter(m => m.workFolderId === workFolderId && m.workFolderName === workFolderName)[0]) {
			newExpanded = newExpanded.filter(m => m.workFolderId !== workFolderId && m.workFolderName !== workFolderName)
		} else {
			const option = {workFolderId, workFolderName}
			newExpanded = newExpanded && newExpanded.length > 0 ? newExpanded.concat(option) : [option]
		}
		localStorage.setItem("fileTreeExpanded", JSON.stringify(newExpanded));
		localStorage.setItem("hasInitFileTreeExpanded", true);
		setFileTreeExpanded(newExpanded)
	}

	const isExpanded = (workFolderId, workFolderName) => {
		return fileTreeExpanded && fileTreeExpanded.length > 0 && fileTreeExpanded.filter(m => m.workFolderId === workFolderId && m.workFolderName === workFolderName)[0]
	}

	return (
		<div className={styles.container}>
			{!(filePenspring && filePenspring.workId && filePenspring.workId !== guidEmpty) && 
				<div className={styles.mainDiv}>
					<div className={classes(styles.menuItem, styles.rowRight)}>
						<Checkbox
							id={`showDocumentDetails`}
							label={`Show document details`}
							labelClass={styles.link}
							position={`before`}
							checked={showDocumentDetails}
							onClick={handleSetShowDocumentDetails} />
					</div>
					<div className={workFolderMineExpanded == undefined || !!workFolderMineExpanded ? styles.show : styles.hide}>
						<FileTreeSubContents
							isWorkAddView={true}
							fileTreeExplorer={fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine')}
							fullFileTreeExplorer={fileTreeExplorer}
							isParentExpanded={workFolderMineExpanded == undefined || !!workFolderMineExpanded}
							assignWorkToWorkFolder={assignWorkToWorkFolder}
							toggleExpanded={toggleExpanded} personId={personId} mineOrOthers={'Mine'}
							toggleExpandedLocal={toggleExpandedLocal}
							isExpanded={isExpanded}
							//setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
							//setGradebookScoreByPenspring={setGradebookScoreByPenspring}
							getMyWorks={getMyWorks} 
							//hasChosenWorkInFolder={hasChosenWorkInFolder}
							chosenWork={filePenspring} setChosenWork={handleSetChosenWork}
							chosenFolder={chosenFolder} setChosenFolder={handleSetChosenFolder}
							showTempControls={showTempControls} setShowFolderControls={setShowFolderControls}
							setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
							isMoveWorkId={isMoveWorkId} moveWorkToFolder={moveWorkToFolder}
							deleteWorkFromFolder={deleteWorkFromFolder}
							deleteWork={props.deleteWork}
							deleteWorkFolder={deleteWorkFolder}
							removeMemberByWorkFolderId={removeMemberByWorkFolderId}
							removeEditorByAuthor={removeEditorByAuthor}
							addWorkFolder={addWorkFolder}
							showDocumentDetails={showDocumentDetails}
							groups={groups}
							setWork={setWork}/>
					</div>
      </div>
			}
		</div>
	);
}

export default WorkListPenspringDrive;

