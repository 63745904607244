import React from 'react';
import styles from './EditorDivFormatControls.module.css';
import EditListChoice from '../EditListChoice'
import HeadlessSelectList from '../HeadlessSelectList'
import Undo from "../../assets/Edit Icons/undo.png";
import Bold from "../../assets/Edit Icons/bold.png";
import Italics from "../../assets/Edit Icons/italics.png";
import Underline from "../../assets/Edit Icons/underline.png";
import Strikeout from "../../assets/Edit Icons/strikeout.png";
import LeftAlign from "../../assets/Edit Icons/left align.png";
import CenterAlign from "../../assets/Edit Icons/center align.png";
import RightAlign from "../../assets/Edit Icons/right align.png";
import FullJustify from "../../assets/Edit Icons/full justify.png";
import Indent from "../../assets/Edit Icons/indent2.png";
import BlockIndent from "../../assets/Edit Icons/block indent left.png";
import BlockIndentUndo from "../../assets/Edit Icons/block indent undo.png";
import UrlLink from "../../assets/Edit Icons/url link.png";
import DocScrollMatch from "../../assets/Edit Icons/doc-scroll-match.png";
import NumberedList from "../../assets/Edit Icons/numbered list.png";
import BulletList from "../../assets/Edit Icons/bullet list.png";
import Download from "../../assets/Edit Icons/download.png";
import FontName from "../../assets/Edit Icons/font name.png";
import FontSize from "../../assets/Edit Icons/font size.png";
import ColorPicker from "../../assets/Edit Icons/color picker.png";
import PaintersPallet from "../../assets/PaintersPallet.png";
import EditorSaveButton from '../EditorSaveButton'
import ColorPickerModal from '../ColorPickerModal'
import RadioGroup from '../RadioGroup'


function EditorDivFormatControls(props) {
	const {
		setFormatChoice, 
		setParagraphAlign, 
		setListChoice, 
		undo, 
		setParagraphIndentChoice, 
		setIsOpenLinkEntry, 
		workSummary, 
		saveWorkSpaceTime,
		isOpenLinkEntry, 
		saveByButtonPress, 
		editChosen, 
		handleSetEditChosen, 
		scrollDocumentToMatch, 
		tabViewElement, 
		isAuthor, 
		handleDownloadChoice,
		handleFontNameChoice, 
		handleFontSizeChoice, 
		handleFontColorChoice, 
		isTranslation, 
		changeCounts, 
		fontFamilyOptions,
		fontSizes,
		handleTextStyleGroupChoice,
		textStyleGroups } = props

	const handleApplyFontFamily = () => {

	}

	const handleApplyTextStyleGroup = () => {

	}

	return (
		<div className={styles.iconRow}>
      <HeadlessSelectList iconAvatar={<img src={Download} className={styles.logo} alt={'edit'} title={'Download'}/>}
                          onSelect={handleDownloadChoice}
                          listOptions={props.downloadOptions}/>
      <div className={styles.dividerLine}>|</div>
			<img src={Undo} className={styles.logo} alt={'edit'} title={'Undo (CTRL+Z)'} onClick={undo}/>
			{/*<div className={styles.dividerLine}>|</div>*/}
			{!isTranslation && 
				<img src={Bold} className={styles.logoSmaller} alt={'edit'} title={'Bold (CTRL+B)'} onClick={() => setFormatChoice('bold')}/>
			}
			{!isTranslation && 
				<img src={Italics} className={styles.logoSmaller} alt={'edit'} title={'Italics (CTRL+I)'} onClick={() => setFormatChoice('italic')}/>
			}
			{!isTranslation && 
				<img src={Underline} className={styles.logoSmaller} alt={'edit'} title={'Underline (CTRL+U)'} onClick={() => setFormatChoice('underline')}/>
			}
			{!isTranslation && 
				<img src={Strikeout} className={styles.logoSmaller} alt={'edit'} title={'Strikeout'} onClick={() => setFormatChoice('strikeout')}/>
			}
			{!isTranslation && isAuthor &&
				<div className={styles.dividerLine}>|</div>
			}
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={PaintersPallet} className={styles.logo} alt={'edit'} title={'Style groups'} />}
					onSelect={handleTextStyleGroupChoice}
					listOptions={textStyleGroups}>
					<div>
						<RadioGroup
							data={[
								{ id: 'ENTIREDOCUMENT', label: 'Apply to the entire document' },
								{ id: 'SENTENCERANGE', label: 'Apply to a range of sentences' },
								{ id: 'CURRENTSENTENCE', label: 'Apply to the current sentence' },
								{ id: 'SELECTEDTEXT', label: 'Apply to the selected text' }]}
							name={`applyFontFamily`}
							horizontal={false}
							onClick={handleApplyTextStyleGroup} />
						<hr />
					</div>
				</HeadlessSelectList>
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList 
					iconAvatar={<img src={FontName} className={styles.logo} alt={'edit'} title={'Font name'}/>}
					onSelect={handleFontNameChoice}
					listOptions={fontFamilyOptions}>
						<div>
							<RadioGroup
								data={[
									{ id: 'ENTIREDOCUMENT', label: 'Apply to the entire document' },
									{ id: 'CURRENTSENTENCE', label: 'Apply to the current sentence' },
									{ id: 'SELECTEDTEXT', label: 'Apply to the selected text' }]}
								name={`applyFontFamily`}
								horizontal={false}
								onClick={handleApplyFontFamily} />
								<hr/>
						</div>
				</HeadlessSelectList>
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList 
					iconAvatar={<img src={FontSize} className={styles.logo} alt={'edit'} title={'Font size'}/>}
					onSelect={handleFontSizeChoice}
					listOptions={fontSizes}/>
			</div>
			<div className={styles.liftIcon}>
				<ColorPickerModal 
					iconAvatar={<img src={ColorPicker} className={styles.logo} alt={'edit'} title={'Font color'}/>}
					onSelect={handleFontColorChoice}/>
			</div>
			{!isTranslation && isAuthor &&
				<div className={styles.dividerLine}>|</div>
			}
			{!isTranslation && isAuthor &&
				<img src={LeftAlign} className={styles.logo} alt={'edit'} title={'Paragraph left align'} onClick={() => setParagraphAlign('left')}/>
			}
			{!isTranslation && isAuthor &&
				<img src={CenterAlign} className={styles.logo} alt={'edit'} title={'Paragraph center align'} onClick={() => setParagraphAlign('center')}/>
			}
			{!isTranslation && isAuthor &&
				<img src={RightAlign} className={styles.logo} alt={'edit'} title={'Paragraph right align'} onClick={() => setParagraphAlign('right')}/>
			}
			{!isTranslation && isAuthor &&
				<img src={FullJustify} className={styles.logo} alt={'edit'} title={'Paragraph full justify'} onClick={() => setParagraphAlign('justify')}/>
			}
			{/*<div className={styles.dividerLine}>|</div>*/}
			{!isTranslation && isAuthor &&
				<img src={Indent} className={styles.logo} alt={'edit'} title={'Indent'} onClick={() => setParagraphIndentChoice('textIndent', '15px')}/>
			}
			{!isTranslation && isAuthor &&
				<img src={BlockIndent} className={styles.logo} alt={`edit`} title={'Block indent'} onClick={() => setParagraphIndentChoice('marginLeft', '15px')}/>
			}
			{!isTranslation && isAuthor &&
				<img src={BlockIndentUndo} className={styles.logo} alt={'edit'} title={'Undo block indent'} onClick={() => setParagraphIndentChoice('marginRight', '15px')}/>
			}
			{!isTranslation && isAuthor &&
				<div className={styles.dividerLine}>|</div>
			}
			{!isTranslation && isAuthor &&
	      <img src={NumberedList} className={styles.logo} alt={'edit'} title={'numbered list'} onClick={() => setListChoice('OL')}/>
			}
			{!isTranslation && isAuthor &&
	      <img src={BulletList} className={styles.logo} alt={'edit'} title={'Bullet list'} onClick={() => setListChoice('UL')}/>
			}
			{/*<div className={styles.dividerLine}>|</div>*/}
			{/*<img src={Images} className={styles.logo} alt={'edit'} title={'Upload image'}/>*/}
			<img src={UrlLink} className={styles.logoSmaller} alt={'edit'} title={'Internet link'} onClick={() => setIsOpenLinkEntry(!isOpenLinkEntry)}/>
			{!isTranslation && props.personId === workSummary.authorPersonId &&
				<EditorSaveButton changeCounts={changeCounts} label={'UPDATE'} saveWorkSpaceTime={saveWorkSpaceTime} saveByButtonPress={saveByButtonPress} addClassName={styles.editorSaveButton}/>
			}
			<div className={styles.editList}>
				<EditListChoice editOptions={props.editOptions} handleSetEditChosen={handleSetEditChosen} editChosen={editChosen} scrollDocumentToMatch={scrollDocumentToMatch}/>
        {isTranslation &&
          <div className={styles.translationMode}>Translation Mode</div>
        }
			</div>
			{tabViewElement && tabViewElement.innerHTML.length > 100 &&
				<img src={DocScrollMatch} className={styles.docScrollMatch} alt={'edit'} title={'Scroll documents to match location'} onClick={() => scrollDocumentToMatch()}/>
			}
		</div>
	)
}

export default EditorDivFormatControls
