import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';


export default function HeadlessSelectList({ iconAvatar, onSelect, listOptions, divDisplayId, children }) {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={{ width: '25px', position: 'relative', top: '-3px' }}
				aria-controls={open ? 'drop-down-list' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{iconAvatar}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						// overflow: 'visible',
						fontSize: '11px',
						maxHeight: '250px',
						overflowY: 'auto',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1,
						'& .MuiAvatar-root': {
							width: 32,
							height: 22,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 8,
							backgroundColor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<div>
					{children}
					{listOptions && listOptions.length > 0 && listOptions.map((m, i) =>
						<MenuItem key={i} onClick={(event) => {
							onSelect(m.value, event, divDisplayId)
							handleClose() }}>
							<span>{m.label}</span>
						</MenuItem>
					)}
				</div>
			</Menu>
		</React.Fragment>
	);
}