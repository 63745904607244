import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './TextStyleSettingView.module.css'
import MessageModal from '../../components/MessageModal'
import ReviewTextModal from '../../components/ReviewTextModal'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import Icon from '../../components/Icon'
import InputText from '../../components/InputText'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'

function TextStyleGroup(props) {
  const navigate = useNavigate()
  const { textStyleGroups, setTextStyleGroup, deleteTextStyleGroup, fontOptions, fontSizes, lineHeights, textAlign, bottomSpace, textIndent } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [textStyleGroupName, setTextStyleGroupName] = useState([])
  const [deleteTextStyleGroupId, setDeleteTextStyleGroupId] = useState()
  const [editTextStyleGroupId, setEditTextStyleGroupId] = useState()
  const [settings, setSettings] = useState({})
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [settingsToReview, setSettingsToReview] = useState('')
  

  const handleDeleteTextStyleGroup = () => {
    deleteTextStyleGroup(deleteTextStyleGroupId)
    setDeleteTextStyleGroupId('')
  }

  const processForm = (event) => {
    event.stopPropagation()
    event.preventDefault()
    let missingFields = ''

    if (!settings.fontFamily) missingFields += "\nFont-family"
    if (!settings.fontSize) missingFields += "\nFont-size"
    if (!settings.lineHeight) missingFields += "\nLine-height"
    if (!settings.textAlign) missingFields += "\nText-align"
    if (!settings.marginBottom) missingFields += "\nParagragh-height"
    if (!settings.textIndent) missingFields += "\nParagraph-indent"
    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, 'red')

    } else {
      setTextStyleGroup(settings, textStyleGroupName, editTextStyleGroupId)
      setEditTextStyleGroupId()
      setTextStyleGroupName('')
      setSettings()
      setSubmitSuccess(true)
    }
  }

  const resetSettings = () => {
    setEditTextStyleGroupId()
    setTextStyleGroupName('')
    setSettings()
  }

  const handleSetEditSettings = (settings) => {
    let convertSettings = settings.entries.reduce((acc,m) => {
      let code = m.code.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
      acc = { ...acc, [code]: m.value }
      return acc
    }, {})
    setSettings(convertSettings)
    setEditTextStyleGroupId(settings.group.textStyleGroupId)
    setTextStyleGroupName(settings.group.name)
    setSubmitSuccess(false)
  }

  const handleSetting = (event) => {
    const value = event.target.value
    const name = event.target.id
    setSettings({...settings, [name]: value})
  }

  const handleSetSettingsToReview = (settings) => {
    let bodyStyle = ''
    let paragraphStyle = ''
    let toView = `<div><div style="font-size: 10pt">Name <div style="font-size: 13pt; font-weight:bold;">${settings.group.name}</div><br/><br/><div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 5px;">BODY</div>`
    let setting = settings.entries.filter(m => m.code === 'font-family')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
      bodyStyle += `${setting.code}: ${setting.value}; `
    }
    setting = settings.entries.filter(m => m.code === 'font-size')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}pt</strong></div></div>`
      bodyStyle += `${setting.code}: ${setting.value}pt; `
    }
    setting = settings.entries.filter(m => m.code === 'line-height')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
      bodyStyle += `${setting.code}: ${setting.value}; `
    }
    toView += `<div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 5px;">PARAGRAPH</div>`
    setting = settings.entries.filter(m => m.code === 'text-align')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
      paragraphStyle += `${setting.code}: ${setting.value}; `
    }
    setting = settings.entries.filter(m => m.code === 'margin-bottom')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">Bottom space:</div><div><strong>${setting.value}px</strong></div></div>`
      paragraphStyle += `${setting.code}: ${setting.value}px; `
    }
    setting = settings.entries.filter(m => m.code === 'text-indent')[0]
    if (setting) {
      toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value} characters</strong></div></div>`
      paragraphStyle += `${setting.code}: ${setting.value/2.2}em; `
    }

    toView += "<br/>EXAMPLE:"
    toView += `<div style="${bodyStyle}"><p style="${paragraphStyle}">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Vivamus vitae sem eget libero vehicula facilisis.Sed in sapien at lectus tristique congue.Fusce auctor tincidunt nisi, nec sodales turpis faucibus in.</p>`
    toView += `<p style="${paragraphStyle}">Praesent ut leo eget libero tincidunt luctus.Maecenas accumsan, risus ut cursus pharetra, felis libero fermentum sapien.</p>`
    setSettingsToReview(toView + "</div></div>")
  }

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Text Style Settings
        </div>
        <div className={styles.background}>
          <table className={styles.tableStyle}>
            <thead>
              <tr>
                <th className={styles.hdr}>Type</th>
                <th className={classes(styles.hdr, isMobile ? styles.textCellMobile : styles.textCell)}>Name</th>
              </tr>
            </thead>
            <tbody>
              {textStyleGroups?.map((m, i) =>
                <tr key={i}>
                  <td>
                    <div className={styles.groupType}>
                      {m.group.standard ? 'Standard' : 'Custom'}
                    </div>
                  </td>
                  <td>
                    <div className={styles.textWhite}>
                      {m.group.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.row}>
                      <div onClick={() => handleSetEditSettings(m)} className={styles.linkWhite}>
                        <Icon pathName={'pencil0'} premium={true} fillColor={'white'} className={styles.pencilEdit} smaller />
                      </div>
                      <div onClick={() => handleSetSettingsToReview(m)} className={styles.linkWhite}>
                        {isMobile ? <div style={{marginTop: '5px'}}><Icon pathName={'magnifier'} premium={true} fillColor={'white'}/></div> : 'view'}
                      </div>
                    </div>
                  </td>
                  <td className={styles.rightCell}>
                    {!m.group.standard && 
                      <div onClick={() => setDeleteTextStyleGroupId(m.group.textStyleGroupId)}>
                        <Icon pathName={'trash2'} premium={true} fillColor={'pink'}/>
                      </div>
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <hr/>
          <div className={styles.titleCustomStyle}>Add a custom text style group</div>
          <div>
            <InputText
              label={"Style settings group name"}
              value={textStyleGroupName}
              inputClassName={styles.textWhite}
              size={"medium-long"}
              name={"textStyleGroupName"}
              onChange={(event) => setTextStyleGroupName(event.target.value)} />
          </div>
          <div className={classes(styles.row, styles.topSpace)}>
            <div className={styles.subLabel}>
              BODY
            </div>
            <div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Font-family
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'fontFamily'}
                    value={settings?.fontFamily || ''}
                    optgroups={fontOptions || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Font-size
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'fontSize'}
                    value={settings?.fontSize || ''}
                    options={fontSizes || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
            </div>
          </div>
          <div className={classes(styles.row, styles.topSpace)}>
            <div className={styles.subLabel}>
              PARAGRAPH
            </div>
            <div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Line-height
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'lineHeight'}
                    value={settings?.lineHeight || ''}
                    options={lineHeights || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Text-align
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'textAlign'}
                    value={settings?.textAlign || ''}
                    options={textAlign || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Bottom space
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'marginBottom'}
                    value={settings?.marginBottom || ''}
                    options={bottomSpace || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.settingText}>
                  Indentation
                </div>
                <div>
                  <SelectSingleDropDown
                    label={``}
                    id={'textIndent'}
                    value={settings?.textIndent || ''}
                    options={textIndent || []}
                    height={`medium`}
                    onChange={handleSetting} />
                </div>
              </div>
            </div>
          </div>          
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={resetSettings}>
                Clear
              </span>
              <span className={styles.cancelButton} onClick={() => navigate(-1)}>
                Cancel
              </span>
              <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
            </div>
          </div>
        </div>
        <div className={styles.buttonRight}>
          <ButtonWithIcon label={'Done'} icon={'checkmark0'} onClick={() => navigate(-1)} submitSuccess={false} className={styles.buttonWidth} />
        </div>
      </div>
      <MessageModal 
        show={deleteTextStyleGroupId} 
        handleClose={() => setDeleteTextStyleGroupId('')}
        heading={``}
        explain={`Are you sure you want to delete this text style setting?`} 
        isConfirmType={true}
        onClick={handleDeleteTextStyleGroup} />
      <ReviewTextModal isOpen={!!settingsToReview} onClose={() => setSettingsToReview('')} text={settingsToReview} label='View Settings'/>
		</div>
	)
}

export default TextStyleGroup