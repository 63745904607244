import React, {useState, useEffect} from 'react';
import styles from './WorkAddDecisions.module.css';
import SelectSingleDropDown from '../SelectSingleDropDown'
import Icon from '../Icon'
import InputText from '../InputText'
import Checkbox from '../Checkbox'
import MessageModal from '../MessageModal'
import ReviewTextModal from '../ReviewTextModal'
import WordProcessorFile from '../../assets/WorkAddIcons/WordProcessorFile.png'
import TextImageFileUpload from '../../assets/WorkAddIcons/TextImageFileUpload.png'
import TextImageFileCamera from '../../assets/WorkAddIcons/TextImageFileCamera.png'
import NewBlankFile from '../../assets/WorkAddIcons/NewBlankFile.png'
import AuthorUser from '../../assets/WorkAddIcons/AuthorUser.png'
import EditorUser from '../../assets/WorkAddIcons/EditorUser.png'
import TeacherUser from '../../assets/WorkAddIcons/TeacherUser.png'
import SingleFile from '../../assets/WorkAddIcons/SingleFile.png'
import NewPenspringChapter from '../../assets/WorkAddIcons/NewPenspringChapter.png'
import ExistingPenspringChapter from '../../assets/WorkAddIcons/ExistingPenspringChapter.png'
import WritingDocument from '../../assets/WorkAddIcons/WritingDocument.png'
import ProgrammingDocument from '../../assets/WorkAddIcons/ProgrammingDocument.png'
import DiscussionPosts from '../../assets/WorkAddIcons/DiscussionPosts.png'
import TextQuiz from '../../assets/WorkAddIcons/TextQuiz.png'
import LiveClassEdit from '../../assets/WorkAddIcons/LiveClassEdit.png'
import TeacherStudent from '../../assets/WorkAddIcons/TeacherStudent.png'
import StudentTeacher from '../../assets/WorkAddIcons/StudentTeacher.png'
import StudentsPeers from '../../assets/WorkAddIcons/StudentsPeers.png'
import TeacherPeers from '../../assets/WorkAddIcons/TeacherPeers.png'
import NativeEdit from '../../assets/WorkAddIcons/NativeEdit.png'
import Translation from '../../assets/WorkAddIcons/Translation.png'
import PaintersPallet from '../../assets/PaintersPallet.png'
import classes from 'classnames'
import { guidEmpty } from '../../utils/GuidValidate'

export default (props) => {
	const { 
		isMobile,
		textStyleGroups,
		fileType, setFileType,
		authorEditor, setAuthorEditor,
		chapters, setChapters,
		documentType, setDocumentType,
		editOrTranslation, setEditOrTranslation,
		textStyleSettings, setTextStyleSettings,		
		workAddSettings,  
		saveWorkAddSetting, deleteWorkAddSetting } = props

	const [name, setName] = useState('')
	const [errorName, setErrorName] = useState('')
	const [entryError, setEntryError] = useState()
	const [saveMessage, setSaveMessage] = useState()
	const [showDeleteMessage, setShowDeleteMessage] = useState()
	const [editorBlankMessage, setEditorBlankMessage] = useState(false)
	const [workAddSetting, setWorkAddSetting] = useState(guidEmpty)
	const [isDefault, setIsDefault] = useState(false)
	const [isInit, setIsInit] = useState(false)
	const [settingsToReview, setSettingsToReview] = useState('')

	useEffect(() => {
		if (!isInit && workAddSettings?.length > 0) {
			const hasDefault = workAddSettings?.filter(m => m.isDefault)[0]
			if (hasDefault) {
				setWorkAddSetting(hasDefault)
				setName(hasDefault.name)
				setIsDefault(hasDefault.isDefault)
				setFileType(hasDefault.fileType)
				setAuthorEditor(hasDefault.authorEditor)
				setChapters(hasDefault.chapters)
				setDocumentType(hasDefault.documentType)
				setEditOrTranslation(hasDefault.editOrTranslation)
			}
			setIsInit(true)
		}
	}, [workAddSettings])

	const handleName = (event) => {
		setName(event.target.value)
		setErrorName('')
	}

	const processForm = (event, workAddSettingId) => {
		let newEntryError = ''
		if (!name) {
			setErrorName("Please enter a document name")
			newEntryError += "Please enter a document name</br>"
		}
		if (newEntryError) {
			setEntryError(newEntryError)
		} else {
			//if this is an update and there is a valie workAddSettingId, then the id will be sent for an update.
			saveWorkAddSetting({ workAddSettingId: workAddSettingId || guidEmpty, name, fileType, authorEditor, chapters, documentType, editOrTranslation, isDefault })
			setSaveMessage(true)
		}
	}

	const handleDeleteSetting = () => {
		setShowDeleteMessage(false)
		deleteWorkAddSetting(workAddSetting.workAddSettingId)
		setWorkAddSetting()
	}

	const handleWorkAddSettingChoice = (workAddSettingId) => {
		const settings = workAddSettings?.filter(m => m.workAddSettingId === workAddSettingId)[0]
		if (settings) {
			setName(settings.name)
			setIsDefault(settings.isDefault)
			setFileType(settings.fileType)
			setAuthorEditor(settings.authorEditor)
			setChapters(settings.chapters)
			setDocumentType(settings.documentType)
			setEditOrTranslation(settings.editOrTranslation)
		}
		setWorkAddSetting(settings)
	}

	const toggleDefaultWorkAddSetting = () => {
		setIsDefault(!isDefault)
	}

	const handleSetFileType = (newFileType) => {
		if (newFileType === 'BLANKFILE' && authorEditor === 'EDITOR') {
			setEditorBlankMessage(true)
			return
		}
		setFileType(newFileType)
	}

	const handleSetAuthorEditor = (newAuthorEditor) => {
		//Don't let the chosen documentType be hidden as chosen if a teacher is chosen but WRITING or PROGRAMMING are still chosen and get past the processForm function.
		if (fileType === 'BLANKFILE' && newAuthorEditor === 'EDITOR') {
			setEditorBlankMessage(true)
			return
		} 
		if (newAuthorEditor === 'TEACHER' || ((newAuthorEditor === 'AUTHOR' || newAuthorEditor === 'EDITOR') && authorEditor === 'TEACHER'))  {
			setDocumentType() 
		}
		setAuthorEditor(newAuthorEditor)
	}

	const handleSetSettingsToReview = (settings) => {
		let bodyStyle = ''
		let paragraphStyle = ''
		let toView = `<div><div style="font-size: 10pt">Name <div style="font-size: 13pt; font-weight:bold;">${settings.group.name}</div><br/><br/><div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 5px;">BODY</div>`
		let setting = settings.entries.filter(m => m.code === 'font-family')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}; `
		}
		setting = settings.entries.filter(m => m.code === 'font-size')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}pt</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}pt; `
		}
		setting = settings.entries.filter(m => m.code === 'line-height')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}; `
		}
		toView += `<div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 5px;">PARAGRAPH</div>`
		setting = settings.entries.filter(m => m.code === 'text-align')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value}; `
		}
		setting = settings.entries.filter(m => m.code === 'margin-bottom')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">Bottom space:</div><div><strong>${setting.value}px</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value}px; `
		}
		setting = settings.entries.filter(m => m.code === 'text-indent')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 105px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value} characters</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value / 2.2}em; `
		}

		toView += "<br/>EXAMPLE:"
		toView += `<div style="${bodyStyle}"><p style="${paragraphStyle}">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Vivamus vitae sem eget libero vehicula facilisis.Sed in sapien at lectus tristique congue.Fusce auctor tincidunt nisi, nec sodales turpis faucibus in.</p>`
		toView += `<p style="${paragraphStyle}">Praesent ut leo eget libero tincidunt luctus.Maecenas accumsan, risus ut cursus pharetra, felis libero fermentum sapien.</p>`
		setSettingsToReview(toView + "</div></div>")
	}

	return (
		<div className={styles.container}>
			<div className={styles.row}>
				{workAddSettings?.length > 0 && 
					<div>
						<SelectSingleDropDown
							label={'Previous document add settings'}
							value={workAddSetting?.workAddSettingId}
							options={workAddSettings}
							height={`long`}
							onChange={(event) => handleWorkAddSettingChoice(event.target.value)} />
					</div>
				}
				{workAddSetting?.workAddSettingId && 
					<div onClick={() => setShowDeleteMessage(true)} className={styles.deleteButton}>
						<Icon pathName={'trash2'} premium={true} fillColor={'red'} />
					</div>
				}
			</div>
			<div className={styles.headLabel}>Step 1 - What type of file?</div>
			<div className={styles.row}>
				<div className={fileType === 'WORDPROCESSOR' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetFileType('WORDPROCESSOR')}>
					<div className={fileType === 'WORDPROCESSOR' ? styles.imageLabelChosen : styles.imageLabel}>{isMobile ? 'Word File' : 'Word Processor File'}</div>
					<div>
						<img src={WordProcessorFile} height={isMobile ? 60 : ''}/>
					</div>
				</div>
				<div className={styles.imageSection}>
					<div className={classes(styles.row, fileType === 'UPLOADTEXTIMAGE' || fileType === 'CAMERATEXTIMAGE' ? styles.imageLabelDoubleChosen : styles.imageLabelDouble)}>
						Text Image File
						<div className={styles.smallText}>
							{isMobile ? 'OCR' : 'OPTICAL CHARACTER RECOGNITION (OCR)'}
						</div>
					</div>
					<div className={styles.row}>
						<div className={fileType === 'UPLOADTEXTIMAGE' ? styles.imageSectionChosen : ''} onClick={() => handleSetFileType('UPLOADTEXTIMAGE')}>
							<div className={styles.imageLabelWhite}>Upload File</div>
							<img src={TextImageFileUpload} height={isMobile ? 60 : ''}/>
						</div>
						<div className={fileType === 'CAMERATEXTIMAGE' ? styles.imageSectionChosen : ''} onClick={() => handleSetFileType('CAMERATEXTIMAGE')}>
							<div className={styles.imageLabelWhite}>Take a picture</div>
							<img src={TextImageFileCamera} height={isMobile ? 60 : ''}/>
						</div>
					</div>
				</div>
				<div className={fileType === 'BLANKFILE' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetFileType('BLANKFILE')}>
					<div className={fileType === 'BLANKFILE' ? styles.imageLabelChosen : styles.imageLabel}>{isMobile ?  'Blank' : 'New Blank File'}</div>
					<div>
						<img src={NewBlankFile} height={isMobile ? 60 : ''}/>
					</div>
				</div>
			</div>

			{/* <div className={styles.headLabel}>Step 2 - Are you the author, editor, or teacher?</div> */}
			<div className={styles.headLabel}>Step 2 - Are you the author or editor?</div>
			<div className={styles.row}>
				<div className={authorEditor === 'AUTHOR' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetAuthorEditor('AUTHOR')}>
					<div className={authorEditor === 'AUTHOR' ? styles.imageLabelAuthorChosen : styles.imageLabelAuthor}>Author</div>
					<div>
						<img src={AuthorUser} height={isMobile ? 100 : 130}/>
					</div>
				</div>
				<div className={authorEditor === 'EDITOR' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetAuthorEditor('EDITOR')}>
					<div className={authorEditor === 'EDITOR' ? styles.imageLabelAuthorChosen : styles.imageLabelAuthor}>Editor</div>
					<div>
						<img src={EditorUser} height={isMobile ? 100 : 130}/>
					</div>
				</div>
				{/* <div className={authorEditor === 'TEACHER' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetAuthorEditor('TEACHER')}>
					<div className={authorEditor === 'TEACHER' ? styles.imageLabelAuthorChosen : styles.imageLabelAuthor}>Teacher</div>
					<div>
						<img src={TeacherUser} height={isMobile ? 100 : 130}/>
					</div>
				</div> */}
			</div>

			<div className={styles.headLabel}>Step 3 - Will there be chapters?</div>
			<div className={styles.row}>
				<div className={chapters === 'SINGLEFILE' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setChapters('SINGLEFILE')}>
					<div className={chapters === 'SINGLEFILE' ? styles.imageLabelChosen : styles.imageLabel}>Single File</div>
					<div>
						<img src={SingleFile} height={isMobile ? 70 : ''}/>
					</div>
				</div>
				<div className={styles.imageSection}>
					<div className={classes(styles.row, chapters === 'NEWPENSPRING' || chapters === 'EXISTINGPENSPRING' ? styles.imageLabelDoubleChosen : styles.imageLabelDouble)}>
						Separate Chapters
						<div className={styles.smallText}>
							UPLOADED INDIVIDUALLY
						</div>
					</div>
					<div className={styles.rowMarginTop}>
						<div className={chapters === 'NEWPENSPRING' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setChapters('NEWPENSPRING')}>
							<img src={NewPenspringChapter} height={isMobile ? 70 : 79} />
						</div>
						<div className={chapters === 'EXISTINGPENSPRING' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setChapters('EXISTINGPENSPRING')}>						
							<img src={ExistingPenspringChapter} height={isMobile ? 70 : 79} />
						</div>
					</div>
				</div>
			</div>

			<div className={styles.headLabel}>Step 4 - What kind of {authorEditor === 'TEACHER' ? 'assignment' : 'document'}?</div>
			{authorEditor !== 'TEACHER' && 
				<div className={styles.row}>
					<div className={documentType === 'WRITING' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('WRITING')}>
						<div className={documentType === 'WRITING' ? styles.imageLabelChosen : styles.imageLabel}>Writing</div>
						<div>
							<img src={WritingDocument} />
						</div>
					</div>
					<div className={documentType === 'PROGRAMMING' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('PROGRAMMING')}>
						<div className={documentType === 'PROGRAMMING' ? styles.imageLabelChosen : styles.imageLabel}>Programming</div>
						<div>
							<img src={ProgrammingDocument} />
						</div>
					</div>
				</div>			
			}
			{authorEditor === 'TEACHER' &&
				<div className={styles.rowWrap}>
					<div className={documentType === 'DISCUSSION' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('DISCUSSION')}>
						<div className={documentType === 'DISCUSSION' ? styles.imageLabelChosen : styles.imageLabel}>Discussion</div>
						<div>
							<img src={DiscussionPosts} />
						</div>
					</div>
					<div className={documentType === 'TESTQUIZ' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('TESTQUIZ')}>
						<div className={documentType === 'TESTQUIZ' ? styles.imageLabelChosen : styles.imageLabel}>Test / Quiz</div>
						<div>
							<img src={TextQuiz} />
						</div>
					</div>
					
					<div className={documentType === 'LIVECLASSEDIT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('LIVECLASSEDIT')}>
						<div className={documentType === 'LIVECLASSEDIT' ? styles.imageLabelChosen : styles.imageLabel}>Live Class</div>
						<div>
							<img src={LiveClassEdit} />
						</div>
					</div>
					<div className={documentType === 'TEACHERSTUDENT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('TEACHERSTUDENT')} tooltip="teacher is author, student edits alone">
						<div className={documentType === 'TEACHERSTUDENT' ? styles.imageLabelChosen : styles.imageLabel}>teacher/student</div>
						<div>
							<img src={TeacherStudent} />
						</div>
					</div>
					<div className={documentType === 'STUDENTTEACHER' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('STUDENTTEACHER')} tooltip="student is author, teacher edits alone">
						<div className={documentType === 'STUDENTTEACHER' ? styles.imageLabelChosen : styles.imageLabel}>student/teacher</div>
						<div>
							<img src={StudentTeacher} />
						</div>
					</div>
					<div className={documentType === 'STUDENTSPEERS' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('STUDENTSPEERS')} tooltip="students are authors, peers edit">
						<div className={documentType === 'STUDENTSPEERS' ? styles.imageLabelChosen : styles.imageLabel}>student/peers</div>
						<div>
							<img src={StudentsPeers} />
						</div>
					</div>
					<div className={documentType === 'TEACHERPEERS' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setDocumentType('TEACHERPEERS')} tooltip="teacher is author, peers edit">
						<div className={documentType === 'TEACHERPEERS' ? styles.imageLabelChosen : styles.imageLabel}>teacher/peers</div>
						<div>
							<img src={TeacherPeers} />
						</div>
					</div>
				</div>
			}
			<div className={styles.headLabel}>Step 5 - Is this an edit or a translation?</div>
			<div className={styles.row}>
				<div className={editOrTranslation === 'NATIVEEDIT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setEditOrTranslation('NATIVEEDIT')}>
					<div className={editOrTranslation === 'NATIVEEDIT' ? styles.imageLabelChosen : styles.imageLabel}>Edit</div>
					<div>
						<img src={NativeEdit} />
					</div>
				</div>
				<div className={editOrTranslation === 'TRANSLATION' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setEditOrTranslation('TRANSLATION')}>
					<div className={editOrTranslation === 'TRANSLATION' ? styles.imageLabelChosen : styles.imageLabel}>Translation</div>
					<div>
						<img src={Translation} />
					</div>
				</div>
			</div>
			<div className={styles.headLabel}>Step 6 - Do you want to overwrite the text style settings?</div>
			<div className={styles.row}>
				<div className={textStyleSettings === '' ? styles.imageSectionChosen : styles.imageSection} onClick={() => setTextStyleSettings('')} style={{width: '100px'}}>
					<div className={textStyleSettings === '' ? styles.imageLabelChosen : styles.imageLabel} style={{ width: '100px' }}>
						Keep Original
					</div>
					<div className={styles.centeredImage}>
						<img src={PaintersPallet} /><br/>
					</div>
					<div className={styles.textStylesList}>
						Font<br />
						Size<br/>
						Indent<br />
						Line height<br />
						Text align<br />
					</div>
				</div>
				{textStyleGroups?.map(m => 
					<div className={textStyleSettings === m.group.textStyleGroupId ? styles.imageSectionChosen : styles.imageSection} onClick={() => setTextStyleSettings(m.group.textStyleGroupId)}>
						<div className={textStyleSettings === m.group.textStyleGroupId ? styles.imageLabelChosen : styles.imageLabel}>{m.group.standard ? 'STANDARD' : 'CUSTOM'}</div>
						<div className={styles.boundingBox}>
							<div style={{marginLeft: '5px'}}>{m.group.name && m.group.name.length > 45 ? m.group.name.substring(0,45) + '...' : m.group.name}</div>
							<div onClick={() => handleSetSettingsToReview(m)} className={styles.viewLink}>view</div>
						</div>
					</div>
				)}
			</div>

			<Checkbox
				label={`Save these settings as your default?`}
				labelClass={styles.labelCheckbox}
				checked={isDefault || false}
				onClick={toggleDefaultWorkAddSetting}
				className={styles.checkbox} />

			<div className={styles.rowLeft}>
				<InputText
					size={"medium"}
					name={"name"}
					label={"Do you want to save this setting with a name?"}
					value={name || ''}
					onChange={handleName}
					error={errorName} />

				<div className={styles.saveButton} onClick={processForm}>Save</div>
				{workAddSetting?.workAddSettingId && 
					<div className={styles.saveButton} onClick={(event) => processForm(event, workAddSetting?.workAddSettingId)}>
						Update
					</div>
				}
			</div>
			<hr />
			<MessageModal displayTempMessage
				show={entryError}
				setEntryError={setEntryError}
				explain={entryError}
				handleClose={() => setEntryError('')}
				heading={`Entry Error!`}
				onClick={() => setEntryError('')} />
			<MessageModal displayTempMessage
				show={saveMessage}
				setEntryError={setSaveMessage}
				explain={'The settings have been saved.'}
				handleClose={() => setSaveMessage(false)}
				heading={``}
				onClick={() => setSaveMessage(false)} />
			<MessageModal displayTempMessage
				show={editorBlankMessage}
				setEntryError={setEditorBlankMessage}
				explain={`An editor cannot choose to enter a blank file. The author's original text needs to be recorded before changes can be made as an editor.`}
				handleClose={() => setEditorBlankMessage(false)}
				heading={``}
				onClick={() => setEditorBlankMessage(false)} />
			<MessageModal show={showDeleteMessage} handleClose={() => setShowDeleteMessage(false)}
				heading={``} explain={`Are you sure you want to delete this setting?`}
				isConfirmType onClick={handleDeleteSetting} />
			<ReviewTextModal isOpen={!!settingsToReview} onClose={() => setSettingsToReview('')} text={settingsToReview} label='View Settings' />
		</div>
	)
};


