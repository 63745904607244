import React, {useState} from 'react';  //PropTypes
import styles from './ColorPickerModal.module.css';
import ButtonWithIcon from '../ButtonWithIcon';
import {SketchPicker} from 'react-color';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

export default function ColorPickerModal({iconAvatar, onSelect}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [color, setColor] = useState('#fff');
	const open = Boolean(anchorEl);

	const handleChangeComplete = (color) => {
		setColor(color.hex);
	};

	const handleClick = (event) => {
		event.preventDefault()
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={{ width: '25px', position: 'relative', top: '-3px' }}
				aria-controls={open ? 'drop-down-list' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{iconAvatar}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						//fontSize: '11px',
						//maxHeight: '250px',
						//overflowY: 'auto',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1,
						'& .MuiAvatar-root': {
							width: 32,
							height: 22,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 8,
							backgroundColor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				style={{zIndex: 99}}
			>
				<SketchPicker color={color} onChangeComplete={handleChangeComplete} />
				<div className={styles.dialogButtons}>
					<a className={styles.noButton}  onClick={handleClose}>Cancel</a>
					<ButtonWithIcon label={`Save`} icon={'checkmark0'} onClick={() => {
						onSelect(color)
						handleClose()
					}}/>
				</div>
			</Menu>
		</React.Fragment>
	)
}
