import React, {useState, useEffect} from 'react'
// Optical Character Reader
// https://www.smashingmagazine.com/2021/06/image-text-conversion-react-tesseract-js-ocr/
import styles from './TextImageUpload.module.css'
import MessageModal from '../MessageModal'
import Required from '../Required'
import SelectSingleDropDown from '../SelectSingleDropDown'
import Icon from '../Icon'
import FileUploadPenspring from '../FileUploadPenspring'
import InputText from '../InputText'
import GoogleDriveLogo from '../../assets/GoogleDriveLogo.png'
import WorkListGoogleDrive from '../WorkListGoogleDrive'
import ReviewTextModal from '../ReviewTextModal'
import { useMediaQuery } from "react-responsive"
import Tesseract from 'tesseract.js'
import classes from 'classnames'
import { Line } from "rc-progress"

function TextImageUpload(props) {
  const { setTextImageUpload, textImageUploads, textImageGroups, textImageGroupId, setTextImageGroupId } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [name, setName] = useState()
  const [progress, setProgress] = useState()
  const [imagePath, setImagePath] = useState()
  const [fileLocal, setFileLocal] = useState({})
  const [fileGoogle, setFileGoogle] = useState({})
  const [entryError, setEntryError] = useState('')
  const [errorTextImageName, setErrorTextImageName] = useState([])
  const [googleDriveOpen, setGoogleDriveOpen] = useState(false)
  const [deleteTextImageUploadId, setDeleteTextImageUploadId] = useState('') //This will actually be the textImageUploadId
  const [textToReview, setTextToReview] = useState();
  const [processedConvertedText, setProcessedConvertedText] = useState();

  useEffect(() => {
    if (textImageUploads && textImageUploads.length > 0) {
      setTextImageGroupId(textImageUploads[0].textImageGroupId)
    }
  }, [textImageUploads])
  
  useEffect(() => {
    if (!processedConvertedText && fileLocal && imagePath) {
      ocrConvertData()
    }
  }, [imagePath, processedConvertedText, fileLocal])
  
  useEffect(() => {
    if (!processedConvertedText && fileGoogle?.blob) {
      Tesseract.recognize(fileGoogle.blob, 'eng', {
        logger: m => setProgress(Math.round(m.progress * 100))
      })
        .catch(err => {
          console.error(err);
        })
        .then(result => {
          if (result?.data?.text) {
            const processedText = detectParagraphs(result.data);
            setTextImageUpload(textImageGroupId, { convertedText: processedText, confidence: result.data.confidence, name });
            setProcessedConvertedText(false)
            setFileGoogle(null)
            setImagePath(null)
          }
        })
    }
  }, [fileGoogle])

  const handleSetFileLocal = (event) => {
    const fileLocal = event.target.files[0];
    if (fileLocal) {
      setFileLocal(fileLocal)
      setProcessedConvertedText(false)
      setName(fileLocal?.name.lastIndexOf('.') > -1 ? fileLocal?.name.substring(0, fileLocal?.name.lastIndexOf('.')) : fileLocal?.name)
      setTimeout(() => setImagePath(URL.createObjectURL(fileLocal)), 300);
    }
  }

  const ocrConvertData = () => {
    if (imagePath) {
      Tesseract.recognize(
        imagePath, 'eng',
        {
          logger: m => setProgress(m.progress * 100)
        }
      )
        .catch(err => {
          console.error(err);
        })
        .then(result => {
          if (result?.data?.text) {
            const processedText = detectParagraphs(result.data);
            setTextImageUpload(textImageGroupId, { convertedText: processedText, confidence: result.data.confidence, name });
            setProcessedConvertedText(false)
            setFileLocal(null)
            setImagePath(null)
          } 
        })
    }
  }

  function detectParagraphs(data) {
    let processedText = '<p>'
    let previousBottom = null
    let previousRight = null
    let averageLeft = data.lines.filter(m => m.baseline.x0 < 100).reduce((sum, line) => sum + line.baseline.x0, 0) / data.lines.length;

    data.lines.forEach((line) => {
      const currentBottom = line.baseline.y1 // Get the bottom coordinate of the current line
      const currentRight = line.baseline.x1 // Get the bottom coordinate of the current line
      const currentLeft = line.baseline.x0 // Get the bottom coordinate of the current line
      if (previousBottom !== null) {
        const gapBottom = currentBottom - previousBottom
        const gapRight = currentRight - previousRight
        if (gapBottom > 100 || gapRight > 100 || currentLeft > averageLeft + 30) {
          if (currentLeft > averageLeft + 30) {
            const textIndent = Math.round((currentLeft - averageLeft) / 5) * 1.2 //1.2 is just the guess at the width of the calculations for pixels in the end.
            processedText += `</p><p style="text-indent: ${textIndent}px;" >`
          } else {
            processedText += '</p><p>'
          }
        }
      }
      processedText += line.text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\|/g, "I") + ' ' //Sanitize the text of dangerous html but maybe this could be a programming code file as well. Plus replace | with the I capital which is a Tesseract thing happening with good typed text that does look like a bar in Microsoft Word.
      previousBottom = currentBottom
      previousRight = currentRight
    })
    return processedText + '</p>'
  }

  const handleSetFileGoogle = (incomingFile) => {
    if (!incomingFile) {  //Then the intention here is to unchoose the previously chosen Google file to start again.
      setFileGoogle({})  
    } else {
      const { blob, file } = incomingFile
      setFileGoogle({ blob, file })
      setProcessedConvertedText(false)
      setName(file?.name.lastIndexOf('.') > -1 ? file?.name.substring(0, file?.name.lastIndexOf('.')) : file?.name)
    }
  }

  const updateTextImageSequence = (event, textImageUpload) => {
    const sequence = event.target.value;
    if (sequence && Number(sequence) !== textImageUpload.sequence) {
      props.updateSequenceTextImageUpload(textImageUpload.textImageUploadId, sequence)
    }
  }
    
  const handleTextImageName = (event, textImageUpload) => {
    const name = event.target.value;
    props.updateNameTextImageUpload(textImageUpload.textImageUploadId, name)
    if (!name) {
      setErrorTextImageName({...errorTextImageName, [textImageUpload.textImageUploadId]: 'The name is required'})
    } else {
      setErrorTextImageName(errorTextImageName?.length > 0 ? errorTextImageName.filter(m => m !== textImageUpload.textImageUploadId) : {})
    }
  }

  const changeGroup = (event) => {
    const chosenTextImageGroupId = event.target.value
    if (chosenTextImageGroupId !== textImageGroupId) {
      props.getTextImageUploads(chosenTextImageGroupId)
      setTextImageGroupId(chosenTextImageGroupId)
    }
  }

  const handleDeleteTextImageUpload = () => {
    props.removeTextImageUpload(deleteTextImageUploadId);
    setDeleteTextImageUploadId('');
  }

	return (
		<div className={styles.container}>
      <form method="post" encType="multipart/form-data">
        <div className={styles.titleRequired}>
          Upload Text Images
          <Required
            setIf={true}
            className={styles.requiredPosition}
            setWhen={textImageUploads?.length > 0} />
        </div>
        {textImageGroups?.length > 0 && 
          <div className={styles.groupList}>
            <SelectSingleDropDown
              label={`Existing groups of text images`}
              value={textImageGroupId}
              options={textImageGroups}
              height={`medium-short`}
              labelClass={styles.textWhite}
              className={styles.selectList}
              onChange={changeGroup} />
          </div>
        }
        <div className={styles.inputControls}>
          <div className={styles.googleDrive}>
            <div className={isMobile ? styles.rowGoogleMobile : styles.rowGoogle} onClick={() => { handleSetFileGoogle(null); setGoogleDriveOpen(!googleDriveOpen);}}>
              <img src={GoogleDriveLogo} height={25} />
              <div className={styles.googleText}>
                Download a text image file from your Google drive
              </div>
            </div>
            {fileGoogle && fileGoogle.file &&
              <div key={'chosen'} onClick={() => handleSetFileGoogle(null)} className={styles.fileGoogleChosen}>
                <input type='radio' checked={true} onChange={() => { }} />
                {fileGoogle?.file?.name}
              </div>
            }
            <div className={googleDriveOpen && !(fileGoogle && fileGoogle.file) ? styles.showGoogleDrive : styles.hide}>
              <WorkListGoogleDrive 
                openList={googleDriveOpen} 
                setFileGoogle={handleSetFileGoogle} 
                fileGoogle={fileGoogle} 
                acceptFiles={['image/bmp', 'image/jpeg', 'image/png', 'image/x-portable-bitmap', 'image/webp']}
                {...props}/>
            </div>
          </div>
          <FileUploadPenspring
            label={isMobile ? 'Select a local text image file or take a picture' : 'Select a local text image file'}
            subLabel='Only one file is allowed at a time - bmp, jpg, png, pbm, webp'
            setFile={handleSetFileLocal} 
            fileTypes='image/bmp,image/jpeg,image/png,image/x-portable-bitmap,image/webp' /> 

          <div>
            <Line percent={progress} strokeWidth="1" strokeColor="#ecb86a" className={styles.widthAdjust} />
          </div>
          <div className={styles.textWhite}>{progress && progress !== 100 ? Math.round(progress) + '%' : ''}&nbsp;</div>

          <table className={styles.tableStyle}>
            <thead>
              <tr>
                <th className={styles.hdr}>#</th>
                <th className={classes(styles.hdr, isMobile ? styles.textCellMobile : styles.textCell)}>Text</th>
                <th className={styles.hdr}></th>
                <th className={styles.hdr}></th>
              </tr>
            </thead>
            <tbody>
              {(!textImageUploads || !textImageUploads.length) &&
                <tr>
                  <td>
                  </td>
                  <td className={styles.emptyCell}>
                    There are not any text images, yet.
                  </td>
                </tr>
              }
              {textImageUploads?.map((m, i) => 
                <tr key={i}>
                  <td>
                    <div className={styles.listTop} key={`sequence{i}`}>
                      <SelectSingleDropDown
                        label={``}
                        noBlank
                        value={m.sequence}
                        options={textImageUploads.reduce((acc, m) => acc && acc.length > 0 ? acc.concat({ id: m.sequence, label: m.sequence }) : [{ id: m.sequence, label: m.sequence }], [])}
                        height={`medium-short`}
                        className={styles.selectList}
                        onChange={(event) => updateTextImageSequence(event, m)} />
                    </div>
                  </td>
                  <td>
                    <div className={styles.imageName} key={`name{i}`}>
                      <InputText
                        key={i}
                        size={isMobile ? 'medium' : 'long'}
                        name={'name'}
                        label={`${m.confidence}% confidence`}
                        labelClassName={styles.labelWhite}
                        inputClassName={styles.textInput}
                        value={m.name || ''}
                        onChange={(event) => handleTextImageName(event, m)}
                        required={true}
                        whenFilled={m.name}
                        error={errorTextImageName[m.textImageUploadId]} />
                    </div>
                  </td>
                  <td>
                    <div onClick={() => setTextToReview(m.convertedText)} className={styles.linkWhite}>
                      {isMobile ? <div style={{marginTop: '5px'}}><Icon pathName={'magnifier'} premium={true} fillColor={'white'}/></div> : 'view'}
                    </div>
                  </td>
                  <td className={styles.rightCell}>
                    <div onClick={() => setDeleteTextImageUploadId(m.textImageUploadId)}>
                      <Icon pathName={'trash2'} premium={true} fillColor={'pink'}/>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
      <MessageModal 
        show={deleteTextImageUploadId} 
        handleClose={() => setDeleteTextImageUploadId('')}
        heading={``}
        explain={`Are you sure you want to delete this text image from this group?`} 
        isConfirmType={true}
        onClick={handleDeleteTextImageUpload} />
      <MessageModal 
        displayTempMessage
        show={entryError}
        setEntryError={setEntryError}
        explain={entryError}
        handleClose={() => setEntryError('')}
        heading={`Entry Error!`}
        onClick={() => setEntryError('')}/>
      <ReviewTextModal isOpen={!!textToReview} onClose={() => setTextToReview('')} text={textToReview} />
		</div>
	)
}

export default TextImageUpload